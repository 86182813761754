body {
  background: rgb(242, 244, 245);
}

nav a {
  margin-bottom: 30px;
}

nav a {
  display: inline-block;
}

nav a.active {
  color: #222222;
  text-decoration: none;
}

nav a + a {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.input-datepicker {
  width: 100%;
}

.category-description {
  text-align: start;
}

.category-description p {
  margin-bottom: 0.5rem;
}

.loader {
  position: fixed;
  top: 45%;
  left: 50%;
  padding: 20px;
}

.loader_ajax_small {
  border: 2px solid #f3f3f3 !important;
  border-radius: 50%;
  border-top: 2px solid #2D2D2D !important;
  width: 29px;
  height: 29px;
  margin: 0 auto;
  -webkit-animation: spin_loader_ajax_small 0.7s linear infinite;
  animation: spin_loader_ajax_small 0.7s linear infinite;
}

@-webkit-keyframes spin_loader_ajax_small {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin_loader_ajax_small {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Custom Style Code */
.ui.dropdown > .dropdown.icon {
  margin: 0em 0em 0em 0.5em;
}

.custom-advert-wrapper .BrainhubCarouselItem,
.custom-advert-wrapper .BrainhubCarousel__dots {
  justify-content: flex-start !important;
}

@media only screen and (max-width: 767px) {
  .ui.modal > .actions {
    padding: 1rem 1rem !important;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .warning-msg-block-wrapper {
    margin: 2rem 1em !important;
  }
  .ui.modal .actions > .button {
    margin-left: 0;
    margin-right: 0.75rem;
  }

  .ui.modal .actions > .button:last-child {
    margin-right: 0;
  }
}

.ps__rail-y {
  z-index: 10000;
}

.warning-msg-block-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 720px;
  margin: 1.5rem auto 1rem;
  padding: 1rem 1.5rem;
  background-color: #f39314bf;
  border-radius: 8px;
}

.warning-msg-block-wrapper.hide {
  display: none !important;
}

.warning-msg-left-block {
  display: flex;
  align-items: center;
}

.warning-msg-left-block img {
  width: 56px;
  height: 56px;
  object-fit: contain;
  margin-right: 10px;
}

.warning-msg-block-text {
  margin-bottom: 0;
}

.warning-msg-block-close-btn {
  cursor: pointer;
  background: none;
  border: none;
}

@media (min-width: 768px) and (max-width: 860px) {
  .custom-main-top-navbar {
    padding: 0.6em 1em 0em !important;
  }

  .custom-main-bottom-navbar {
    padding: 0.2em 1em !important;
  }

  .custom-search-form {
    width: 49% !important;
  }

  .custom-mobile-categories .column {
    max-width: 500px !important;
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
  }

  .custom-advice-price {
    font-size: 21px !important;
  }

  .custom-advice-cbu-price {
    font-size: 14px !important;
  }

  .custom-advice-user-info {
    margin-left: 8px !important;
  }
}


